<template>
  <main>
      <h1 class="headline blue--text text-center mt-4 mb-3">Verkkomyynti-raportti</h1>
      <v-divider></v-divider>
      <v-row>
          <v-col>
              <v-select
                  v-model="product_type_id"
                  :items="types"
                  :return-object="false"
                  item-value="id"
                  label="Valitse tuotetyyppi"
                  clearable
              >
                  <template #item="{ item }">
                      {{ item.name }}
                  </template>
                  <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                      </span>
                  </template>
              </v-select>
          </v-col>

          <v-col>
              <v-select
                  v-model="payment_method_id"
                  :items="payment_methods"
                  :return-object="false"
                  item-value="id"
                  label="Valitse maksutapa"
                  clearable
              >
                  <template #item="{ item }">
                      {{ item.name }}
                  </template>
                  <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                      </span>
                  </template>
              </v-select>
          </v-col>

          <v-col>
              <v-select
                  v-model="domain_id"
                  :items="domains"
                  :return-object="false"
                  item-value="id"
                  label="Valitse domain"
                  clearable
              >
                  <template #item="{ item }">
                      {{ item.name }}
                  </template>
                  <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                      </span>
                  </template>
              </v-select>
          </v-col>

          <v-col>
              <v-select
                  v-model="group_by"
                  :items="groupby_netsales"
                  :return-object="false"
                  item-value="id"
                  multiple
                  clearable
                  label="Valitse järjestely"
              >
              </v-select>
          </v-col>
      </v-row>
      Valitse päivämäärä:
      <v-row>
          <v-col cols="2">
              <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
          </v-col>
          <v-col cols="2">
              <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
          </v-col>
          <v-col cols="1">
              <v-btn
                      class="v-btn ma-3 green float-right"
                      @click="getResource"
                      small
              >
                  HAE
              </v-btn>
          </v-col>
      </v-row>

      <v-row v-if="Object.keys(resource).length > 0" style="max-width: 1100px">
          <v-col v-for="btn in resourceSelectors" :key="btn.id" class="text-center">
              <v-btn
                  small
                  @click="selectedResourceSelector = btn.id"
              >
                  {{ btn.name }}
              </v-btn>
          </v-col>
      </v-row>

      <v-simple-table
          class="mb-12"
          v-if="Object.keys(resource).length > 0"
      >
          <thead>
          <tr>
              <th>
                Päivä    &nbsp;
              </th>
              <th>
                Myynti (alv25.5%)
              </th>
              <th>
                  Tuloutus    &nbsp;
              </th>
              <th>
                  Myyntitapahtumia / kpl
              </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="tr in filteredResource">
              <tr :key="tr.current_date">
                  <td>
                      {{ tr.current_date }}
                      <template v-if="tr.current_level.length > 0">
                          <v-icon v-if="breakdown_visibility != tr.current_date" @click="breakdown_visibility = tr.current_date">mdi-arrow-down</v-icon>
                          <v-icon v-else @click="breakdown_visibility = null">mdi-arrow-up</v-icon>
                      </template>
                  </td>
                  <td>
                      {{ tr.turnover }} €
                  </td>
                  <td>
                      {{ tr.accounting }} €
                  </td>
                  <td>
                      {{ tr.amount }}
                  </td>
              </tr>
              <template v-if="breakdown_visibility == tr.current_date">
                  <template v-for="(tr2, key) in tr.current_level">
                      <tr :key="'tr2_'+key" style="color: darkgrey">
                          <td>
                              {{ breakdownName(tr2) }}
                          </td>
                          <td>
                              {{ tr2.turnover }} €
                          </td>
                          <td>
                              {{ tr2.accounting }} €
                          </td>
                          <td>
                              {{ tr2.amount }}
                          </td>
                      </tr>

                      <!-- optional second level row -->
                      <template v-if="tr2.current_level.length > 0">
                          <template v-for="(tr3, key2) in tr2.current_level">
                              <tr :key="'tr3_'+key2" style="color: darkgrey">
                                  <td>
                                      <v-icon class="ml-2">mdi-arrow-right-bottom</v-icon>{{ breakdownName(tr3) }}
                                  </td>
                                  <td>
                                      {{ tr3.turnover }} €
                                  </td>
                                  <td>
                                      {{ tr3.accounting }} €
                                  </td>
                                  <td>
                                      {{ tr3.amount }}
                                  </td>
                              </tr>

                              <!-- optional third level row -->
                              <template v-if="tr3.current_level.length > 0">
                                  <template v-for="(tr4, key3) in tr3.current_level">
                                      <tr :key="'tr4_'+key3" style="color: darkgrey">
                                          <td>
                                              <v-icon class="ml-6">mdi-arrow-right-bottom</v-icon>{{ breakdownName(tr4) }}
                                          </td>
                                          <td>
                                              {{ tr4.turnover }} €
                                          </td>
                                          <td>
                                              {{ tr4.accounting }} €
                                          </td>
                                          <td>
                                              {{ tr4.amount }}
                                          </td>
                                      </tr>
                                  </template>
                              </template>

                          </template>
                      </template>

                  </template>
              </template>
          </template>
          </tbody>
      </v-simple-table>

  </main>
</template>

<script>
export default {
    name: "NetsalesReport",
    data: function () {
        return {
            startDate: null,
            endDate: null,
            resource: [],
            types: [],
            domains: [],
            payment_methods: [],
            groupby_netsales: [],
            product_type_id: null,
            payment_method_id: null,
            domain_id: null,
            resourceLoading: false,
            group_by: [],
            breakdown_visibility: null,
            resourceSelectors: [
                {id: 'daily_data', name: 'Päiväkohtainen'},
                {id: 'weekly_data', name: 'Viikottainen'},
                {id: 'monthly_data', name: 'Kuukausittainen'},
                {id: 'quaterly_data', name: 'Kvartaalittainen'},
                {id: 'yearly_data', name: 'Vuosittainen'},
            ],
            selectedResourceSelector: 'daily_data',
        }
    },
    methods: {

        breakdownName(data){

            if(data.operator) return data.operator.name
            if(data.domain) return data.domain.name
            if(data.product_type) return data.product_type.name
            if(data.service) return data.service.name
            if(data.payment_type) return data.payment_type.name

        },

        async getResource(){
            this.resourceLoading = true
            this.resource = []
            await this.axios.get('reports/net_sales', {
                params: {
                    from: this.startDate,
                    to: this.endDate,
                    product_type_id: this.product_type_id,
                    payment_method_id: this.payment_method_id,
                    domain_id: this.domain_id,
                    group_by_1: typeof this.group_by[0] !== 'undefined' ? this.group_by[0] : null,
                    group_by_2: typeof this.group_by[1] !== 'undefined' ? this.group_by[1] : null,
                    group_by_3: typeof this.group_by[2] !== 'undefined' ? this.group_by[2] : null,
                }
            }).then((resources) => {
                this.resource = resources.data
            }).catch ((error) => {
                console.error(error);
            });
            this.resourceLoading = false
        },

    },
    computed: {
        filteredResource(){

            if(this.resource) return this.resource[this.selectedResourceSelector]

            return []
        }
    },
    mounted(){
        this.startDate = this.$day().startOf('month').format("DD.MM.YY")
        this.endDate = this.$day().format("DD.MM.YYYY")

        this.axios.get('net_product/types').then((r) => {this.types = r.data}) //get projects_list
        this.axios.get('net_product/payment_methods').then((r) => {this.payment_methods = r.data}) //get group_list
        this.axios.get('reports/groupby_net_sales').then((r) => {this.groupby_netsales = r.data}) //get media_list
        this.axios.get('domains').then((r) => {this.domains = r.data}) //get numbers_list (?keyword=05054)
    }
}
</script>

<style scoped>

</style>